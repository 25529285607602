import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { NgSelectModule } from '@ng-select/ng-select';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HomeComponent } from './extrapages/home/home.component';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { BaseComponent } from './base/base/base.component';
import { HttpErrorInterceptor } from 'src/network/http-error.interceptor';
import { ErrorMessageModalComponent } from './pages/error-message-modal.component';
import { ModalModule } from 'ngx-bootstrap/modal';
import { LayoutsModule } from './layouts/layouts.module';
import { UnderMaintenanceModalComponent } from './pages/under-maintenance-modal/under-maintenance-modal.component';
import { ToastrModule } from 'ngx-toastr';
import { AccordionModule } from 'ngx-bootstrap/accordion';
import { NgOtpInputModule } from 'ng-otp-input';
import { StringVariable } from 'src/core/string-variable';
import { NgxQrcodeStylingModule } from 'ngx-qrcode-styling';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgHttpLoaderModule } from 'ng-http-loader';
import { MarkdownModule } from 'ngx-markdown';

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    BaseComponent,
    UnderMaintenanceModalComponent,
    ErrorMessageModalComponent,
  ],
  imports: [
    BrowserAnimationsModule,
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    NgSelectModule,
    NgxQrcodeStylingModule,
    ReactiveFormsModule,
    HttpClientModule,
    ModalModule.forRoot(),
    AccordionModule.forRoot(),
    NgOtpInputModule,
    NgHttpLoaderModule.forRoot(),
    ToastrModule.forRoot({
      timeOut: 1000,
      positionClass: 'toast-top-center',
    }),
    LayoutsModule,
    MarkdownModule.forRoot(),
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpErrorInterceptor,
      multi: true,
    },
    StringVariable,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
