import {
  HttpClient,
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpHeaders,
  HttpInterceptor,
  HttpRequest,
  HttpResponse,
  HttpStatusCode,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  catchError,
  finalize,
  Observable,
  of,
  retry,
  switchMap,
  tap,
  throwError,
} from 'rxjs';
import { LocalStorageService } from './services/local-storage.service';
import { environment } from 'src/environments/environment';
import { v4 as uuidv4 } from 'uuid';
import { GenericError } from 'src/models/generic-error';
import { BsModalService, ModalOptions } from 'ngx-bootstrap/modal';
import { ErrorAction } from 'src/enums/error-action';
// import { ModalArguments } from "src/models/modal-arguments";
import { ModalButtonType } from 'src/enums/modal_button_type';
import { Location } from '@angular/common';
import { MessageType } from 'src/enums/message-type';
import { DialogUtil } from '../utils/dialog-utils';
import { ModalArguments } from '../models/modal-arguments';
import { UnderMaintenanceModalComponent } from 'src/app/pages/under-maintenance-modal/under-maintenance-modal.component';
import { KeywordsAndConstants } from 'src/core/keywords-and-constants';
import { JwtHelperService } from '@auth0/angular-jwt';
import { AdminLoginService } from './services/admin/admin-login.service';
import { ProfileResponseModel } from '../models/profile.model';
import { Apis } from '../core/apis';
import { Router } from '@angular/router';
import { SpinnerVisibilityService } from 'ng-http-loader';

@Injectable({
  providedIn: 'root',
})
export class HttpErrorInterceptor implements HttpInterceptor {
  // modalRef?: BsModalRef;
  private refreshTokenRequestCount: number = 0;
  private tokenBlockRequestCount: number = 0;
  private logoutDurToFailedRefreshTokenRequestThreshold: number = 3;
  private errorDialogCount: number = 0;
  private dialogCount: number = 0;
  private dialogCountForResetPassword: number = 0;
  private currentUrlsOnProgress: number = 0;
  private gettingReportFormatResponse: boolean = false;

  constructor(
    private http: HttpClient,
    private _service: LocalStorageService,
    private dialog: DialogUtil,
    public constants: KeywordsAndConstants,
    private modalServiceForMaintenanceModal: BsModalService,
    private _loginService: AdminLoginService,
    public router: Router,
    private location: Location,
    private loader: SpinnerVisibilityService
  ) {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    if (
      !this._service.getToken() &&
      !request.url.toString().includes('refreshToken')
    ) {
      request = request.clone({
        headers: new HttpHeaders({
          'Access-Control-Allow-Origin': environment.allowed_origin,
          'ngrok-skip-browser-warning': '62490',
          trackingId: uuidv4(),
        }),
      });
    } else if (request.url.toString().includes('refreshToken')) {
      request = request.clone({
        headers: new HttpHeaders({
          'Access-Control-Allow-Origin': environment.allowed_origin,
          Authorization: `Bearer ${this._service.getRefreshToken()}`,
          trackingId: uuidv4(),
          'ngrok-skip-browser-warning': '62490',
        }),
      });
    } else if (
      request.url.toString().includes('https://atlpay-bucket') ||
      request.url.toString().includes('https://atlpay-test-bucket') ||
      request.url.toString().includes('https://atlpay-bucket-dev-and')
    ) {
      request = request.clone({
        headers: new HttpHeaders({
          'Access-Control-Allow-Origin': 'https://app.atlpay.com',
        }),
      });
    } else {
      request = request.clone({
        headers: new HttpHeaders({
          'Access-Control-Allow-Origin': environment.allowed_origin,
          Authorization: `Bearer ${this._service.getToken()}`,
          trackingId: uuidv4(),
          'ngrok-skip-browser-warning': '62490',
        }),
      });
    }
    return this.handleRequest(next, request);
  }

  private handleRequest(next: HttpHandler, request: HttpRequest<any>) {
    this.currentUrlsOnProgress++;
    if (
      request.url.includes('getReportFormatResponse') ||
      (request.url.includes('byCategory') && this.gettingReportFormatResponse)
    ) {
      this.loader.hide();
      this.gettingReportFormatResponse = true;
    } else {
      if (this.gettingReportFormatResponse) {
        this.loader.show();
      }
    }
    return next.handle(request).pipe(
      retry(0),
      catchError((error: HttpErrorResponse) => {
        let genericError: GenericError = new GenericError();
        if (error.error instanceof ErrorEvent) {
          throw new GenericError(0, error.error.message);
        } else {
          genericError.message = error.error.message;
          genericError.code = error.error.code;
          if (
            error.error.message != undefined &&
            error.status == HttpStatusCode.BadRequest
          ) {
            if (error.error.code == 400001) {
              genericError.errorAction = ErrorAction.HANDLED;
              // this.modalRef = this.modalService.show(error.error.message);
            }
          } else {
            if (error.status == HttpStatusCode.Unauthorized) {
              if (error.error.code == 401001) {
                if (request.url.includes('refreshToken')) {
                  this._service.logout();
                } else {
                  return this.refreshToken(next, request);
                }
              } else if (error.error.code == 401003) {
                this.tokenBlockRequestCount++;
                if (this.tokenBlockRequestCount == 1) {
                  return this.refreshToken(next, request);
                }
              } else {
              }
            } else if (error.status == HttpStatusCode.Forbidden) {
              if (error.error.code == 403001) {
                genericError.errorAction = ErrorAction.HANDLED;
                this.router.navigateByUrl('/twoFactor', {
                  state: { Access: true },
                });
              } else if (error.error.code == 403007) {
                genericError.errorAction = ErrorAction.HANDLED;
                const contextAlias = this;
                this.dialogCount++;
                if (this.dialogCount == 1) {
                  this.dialog.errorShowModal(
                    new ModalArguments(error.error.message)
                      .withButtonType(ModalButtonType.DOUBLE_BUTTON)
                      .withButtonPositiveText('Added to trusted devices')
                      .withButtonNegativeText('Resend')
                      .dontDismissNegativeButtonOnClick()
                      .withCancellable(false)
                      .withCallback({
                        buttonNegativeClicked() {
                          contextAlias.http
                            .get(Apis.sendMail)
                            .subscribe((res: any) => {
                              contextAlias.dialog.showToast(
                                res.message,
                                MessageType.SUCCESS
                              );
                              setTimeout(
                                () => (contextAlias.dialogCount = 0),
                                300
                              );
                              console.log(contextAlias.router.url);
                              if (contextAlias.router.url == '/dashboard') {
                                contextAlias.router.navigateByUrl('');
                              } else {
                                contextAlias.location.back();
                              }
                            });
                        },
                        buttonPositiveClicked() {
                          contextAlias.http
                            .get(Apis.refreshToken)
                            .subscribe((res: any) => {
                              if (res) {
                                contextAlias._service.backUpToken =
                                  JSON.stringify(res.token);
                                contextAlias._service.backUpRefreshToken =
                                  JSON.stringify(res.refreshToken);
                                localStorage.setItem(
                                  contextAlias.constants.LocalStorageToken,
                                  JSON.stringify(res.token)
                                );
                                localStorage.setItem(
                                  contextAlias.constants
                                    .LocalStorageRefreshToken,
                                  JSON.stringify(res.refreshToken)
                                );
                                contextAlias.dialogCount = 0;
                                console.log(contextAlias.router.url);
                                if (contextAlias.router.url == '/dashboard') {
                                  contextAlias.router.navigateByUrl('');
                                } else {
                                  contextAlias.location.back();
                                }
                              }
                            });
                        },
                        buttonSingleClicked() {},
                      })
                  );
                }
              } else if (error.error.code == 403008) {
                genericError.errorAction = ErrorAction.HANDLED;
                if (
                  request.url.includes(
                    'impersonation/checkIfOTPRequiredForImpersonation'
                  )
                ) {
                  return throwError(error.error);
                } else {
                  const contextAlias = this;
                  this.dialog.errorShowModal(
                    new ModalArguments(error.error.message)
                      .withButtonType(ModalButtonType.SINGLE_BUTTON)
                      .withButtonSingleText('Ok')
                      .withCallback({
                        buttonNegativeClicked() {},
                        buttonPositiveClicked() {},
                        buttonSingleClicked() {
                          contextAlias.errorDialogCount = 0;
                          contextAlias.location.back();
                        },
                      })
                  );
                }
              } else if (error.error.code == 403009) {
                // genericError.errorAction = ErrorAction.HANDLED;
                // this.errorDialogCount++;
                // if (this.errorDialogCount == 1) {
                //   const contextAlias = this;
                //   this.dialog.errorShowModal(
                //     new ModalArguments(error.error.message)
                //       .withButtonType(ModalButtonType.SINGLE_BUTTON)
                //       .withButtonSingleText('Ok')
                //       .withCallback({
                //         buttonNegativeClicked() {
                //         },
                //         buttonPositiveClicked() {
                //         },
                //         buttonSingleClicked() {
                //           contextAlias.errorDialogCount = 0;
                //           contextAlias.location.back();
                //         },
                //       })
                //       .withCancellable(false)
                //   );
                // }
              } else if (error.error.code == 403010) {
                genericError.errorAction = ErrorAction.HANDLED;
                const contextAlias = this;
                this.dialogCountForResetPassword++;
                if (this.dialogCountForResetPassword == 1) {
                  this.dialog.errorShowModal(
                    new ModalArguments(error.error.message)
                      .withButtonType(ModalButtonType.SINGLE_BUTTON)
                      .withButtonSingleText('Ok')
                      .withCallback({
                        buttonNegativeClicked() {},
                        buttonPositiveClicked() {},
                        buttonSingleClicked() {
                          contextAlias.router.navigateByUrl(
                            '/password/change-password',
                            {
                              replaceUrl: true,
                            }
                          );
                        },
                      })
                      .withCancellable(false)
                  );
                }
              } else if (error.error.code == 403011) {
                genericError.errorAction = ErrorAction.HANDLED;
                const contextAlias = this;
                this.dialog.errorShowModal(
                  new ModalArguments(error.error.message)
                    .withButtonType(ModalButtonType.SINGLE_BUTTON)
                    .withButtonSingleText('Ok')
                    .withCallback({
                      buttonNegativeClicked() {},
                      buttonPositiveClicked() {},
                      buttonSingleClicked() {
                        contextAlias.router.navigateByUrl('/verification', {
                          replaceUrl: true,
                        });
                      },
                    })
                );
              } else if (error.error.code == 403012) {
                genericError.errorAction = ErrorAction.HANDLED;
                this.router.navigateByUrl('/twoFactor');
              }
            } else if (
              error.statusText == 'Request Entity Too Large' ||
              error.status == 413
            ) {
              genericError.errorAction = ErrorAction.HANDLED;
              this.dialog.showToast(error.error.message, MessageType.ERROR);
            } else if (error.status == HttpStatusCode.ServiceUnavailable) {
              genericError.errorAction = ErrorAction.HANDLED;
              let errorCode = error.error.code;
              let message = error.error.message;
              const initialState = {
                errorCode: errorCode,
                message: message,
              };
              const modalOptions: ModalOptions = {
                ignoreBackdropClick: true,
                keyboard: false,
              };
              this.modalServiceForMaintenanceModal.show(
                UnderMaintenanceModalComponent,
                { initialState, ...modalOptions }
              );
              localStorage.setItem(
                this.constants.isModalOpen,
                JSON.stringify(true)
              );
            } else {
              genericError.errorAction = ErrorAction.HANDLED;
              let message = 'Service Unavailable';
              const initialState = {
                errorCode: 0,
                message: message,
              };
              const modalOptions: ModalOptions = {
                ignoreBackdropClick: true,
                keyboard: false,
              };
              this.modalServiceForMaintenanceModal.show(
                UnderMaintenanceModalComponent,
                { initialState, ...modalOptions }
              );
            }
          }
        }
        return throwError(genericError);
      }),
      tap((response) => {
        if (response instanceof HttpResponse) {
          this.currentUrlsOnProgress--;
          if (this.currentUrlsOnProgress == 0) {
            if (this.gettingReportFormatResponse) {
              this.loader.hide();
              this.gettingReportFormatResponse = false;
            }
          }
        }
      })
    );
  }

  private refreshToken(next: HttpHandler, request: HttpRequest<any>) {
    this.refreshTokenRequestCount++;
    let existingUniqueId = localStorage.getItem('uniqueIdentifierId');
    return this.refreshAccessToken().pipe(
      switchMap((refreshTokenResponse: any) => {
        if (refreshTokenResponse) {
          localStorage.setItem(
            this.constants.LocalStorageToken,
            JSON.stringify(refreshTokenResponse.token)
          );
          localStorage.setItem(
            this.constants.LocalStorageRefreshToken,
            JSON.stringify(refreshTokenResponse.refreshToken)
          );
          this._service.backUpToken = JSON.stringify(
            refreshTokenResponse.token
          );
          this._service.backUpRefreshToken = JSON.stringify(
            refreshTokenResponse.refreshToken
          );
          localStorage.setItem('uniqueIdentifierId', existingUniqueId!!);
          const helper = new JwtHelperService();
          const decodedToken = helper.decodeToken(refreshTokenResponse.token);
          localStorage.setItem(this.constants.isLoginType, decodedToken.type);
          if (localStorage.getItem(this.constants.isLoginType) == 'ADMIN') {
            this._loginService
              .user()
              .subscribe((user: ProfileResponseModel) => {
                localStorage.setItem(
                  this.constants.LocalStorageUserInfo,
                  JSON.stringify(user)
                );
              });
          }
          return next.handle(this.addAuthenticationToken(request));
        } else {
          return of();
        }
      }),
      catchError((error: any) => {
        this._service.logout();
        return of();
      }),
      finalize(() => {
        this.refreshTokenRequestCount = 0;
        this.tokenBlockRequestCount = 0;
      })
    );
  }

  private refreshAccessToken(): Observable<any> {
    return this.http.get(Apis.refreshToken, {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': environment.allowed_origin,
        Authorization: `Bearer ${this._service.getRefreshToken()}`,
      }),
    });
  }

  private addAuthenticationToken(request: HttpRequest<any>): HttpRequest<any> {
    if (request.url.includes('refreshToken')) {
      return request.clone({
        headers: new HttpHeaders({
          'Access-Control-Allow-Origin': environment.allowed_origin,
          Authorization: `Bearer ${this._service.getRefreshToken()}`,
        }),
      });
    } else {
      return request.clone({
        headers: new HttpHeaders({
          'Access-Control-Allow-Origin': environment.allowed_origin,
          Authorization: `Bearer ${this._service.getToken()}`,
        }),
      });
    }
  }
}
